<template>
  <div class="flex justify-center text-white relative bg-[#0B0A0A]">
    <img
      :src="bg"
      alt="bg"
    >
    <div class="absolute top-67/100 md:top-68/100 flex flex-col gap-12 text-2xl md:(gap-38 text-6xl)">
      <div
        v-for="domain in domains"
        class="w-50 py-2 md:(w-120 py-4) rounded-lg border flex items-center justify-center hover:underline"
      >
        <a
          :href="`http://${domain}`"
          target="_blank"
        >
          {{ domain }}
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import bg from '@/assets/domain/bg.png'
import axios from 'axios'
import { onMounted, ref } from "vue"

const domains = ref([])
onMounted(async () => {
  const {data} = await axios.get('api/domains')
  domains.value = data.data.data.map(o => o.domain)
})
</script>
